import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, combineLatest, filter, map, merge, of, switchMap, take, withLatestFrom } from 'rxjs';
import * as RidesActions from './rides.actions';
import { Action, Store } from '@ngrx/store';
import { HomeSelectors } from '../../store';
import { RidesSelectors } from '.';
import { RidesFirestoreService } from '../services/rides-firestore.service';
import { RidesTableVM, RideTableRow } from './rides.reducer';
import {
  Area,
  BookingAggregate,
  DriverTelemetry,
  Package,
  Quote,
  Vehicle,
  VehicleType,
} from '../../../shared/models/firestore.model';
import { DateHelperService } from '../../../shared/services/date-helper.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { getPaymentMethodType, getRideStatus } from './mappings';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import { B2BWebApiService } from 'src/app/shared/services/b2b-web-api.service';
import { uuid } from 'src/app/shared/utils/uuid';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthSelectors } from 'src/app/auth/store';
import { RideStatus } from 'src/app/shared/models/rides.vm';
import { QuoteRequest, UpdateBookingRequest } from 'src/app/shared/models/b2b-web-api.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmQuoteChangeDialogComponent } from 'src/app/shared/components/ride-details/dialogs/confirm-quote-change-dialog/confirm-quote-change-dialog.component';
import { whenPageVisible } from '../../../shared/utils/when-page-visible';
import { StringUtils } from 'src/app/shared/utils/string-utils';
import { EditPickupTimeDialogComponent } from 'src/app/shared/components/ride-details/dialogs/edit-pickup-time-dialog/edit-pickup-time-dialog.component';
import { PaymentMethodType } from 'src/app/shared/models/payment-methods.model';

@Injectable()
export class RidesEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private ridesFirestoreService = inject(RidesFirestoreService);
  private dateHelperService = inject(DateHelperService);
  private firestoreService = inject(FirestoreService);
  private webApiService = inject(B2BWebApiService);
  private notification = inject(NotificationService);
  private dialog = inject(MatDialog);

  usersComponentInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.ridesComponentInit),
      switchMap(_ =>
        this.store.select(HomeSelectors.selectBusinessId).pipe(
          filter(businessId => !!businessId),
          take(1),
        ),
      ), // wait for business to be loaded
      switchMap(_ => merge(this.watchDataSourceParameters(), this.watchAreas(), this.watchPlatformSettings())),
    ),
  );

  tabChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.tabChanged),
      switchMap(_ => merge(this.watchDataSourceParameters())),
    ),
  );

  rideSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.rideSelected, RidesActions.bookingCancelled, RidesActions.pickupTimeUpdated),
      switchMap(action =>
        this.ridesFirestoreService.watchBookingAggregate(action.id).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
          filter(aggregate => !!aggregate),
          switchMap(aggregate =>
            combineLatest([
              this.getQuote(aggregate),
              this.getPackage(aggregate),
              this.getDriver(aggregate),
              this.getVehicle(aggregate),
              this.getVehicleType(aggregate),
            ]).pipe(
              whenPageVisible(),
              takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
              switchMap(([quote, productPackage, driver, vehicle, vehicleType]) => {
                const newAggregate = {
                  ...aggregate,
                  quote,
                  productPackage,
                  driver,
                  vehicle,
                  vehicleType,
                };
                return [newAggregate];
              }),
            ),
          ),
        ),
      ),
      switchMap(aggregate => [RidesActions.rideDetailsChanged({ aggregate })]),
    ),
  );

  bookingCancelClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.bookingCancelClicked),
      withLatestFrom(
        this.store.select(AuthSelectors.selectFcUserId),
        this.store.select(RidesSelectors.selectSelectedRideId),
      ),
      switchMap(([action, userId, selectedRideId]) =>
        this.webApiService
          .cancelBooking({
            id: selectedRideId,
            userId,
            idempotenceKey: uuid(),
          })
          .pipe(
            switchMap(_ =>
              this.firestoreService.watchBookingAggregate(selectedRideId).pipe(
                whenPageVisible(),
                takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
                filter(booking => {
                  const rideStatus = getRideStatus(booking);
                  return rideStatus === RideStatus.CANCELLED;
                }),
                take(1),
                switchMap(_ => [RidesActions.bookingCancelled({ id: selectedRideId })]),
              ),
            ),
            catchError(error => {
              this.notification.error(error);
              return [RidesActions.bookingCancelFailed()];
            }),
          ),
      ),
    ),
  );

  bookingCancelled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.bookingCancelled),
      tap(() => this.notification.success('Booking cancelled')),
      switchMap(_ => merge(this.watchDataSourceParameters())),
    ),
  );

  sendTrackingLinkClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.sendTrackingLinkClicked),
      withLatestFrom(this.store.select(RidesSelectors.selectSelectedRideId)),
      switchMap(([action, selectedRideId]) => {
        return this.webApiService.sendTrackingSms(selectedRideId, action.phoneNumber).pipe(
          tap(() => this.notification.success('Tracking link sent')),
          map(_ => RidesActions.trackingLinkSent()),
          catchError(error => {
            this.notification.error(error);
            return [RidesActions.sendTrackingLinkFailed()];
          }),
        );
      }),
    ),
  );

  callDriverClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.callDriverClicked),
      withLatestFrom(
        this.store.select(RidesSelectors.selectSelectedRideId),
        this.store.select(RidesSelectors.selectBusinessSiteId),
      ),
      switchMap(([action, selectedRideId, businessSiteId]) => {
        return this.webApiService
          .callDriver({
            agentId: businessSiteId,
            bookingId: selectedRideId,
            callerPhoneNumber: action.phoneNumber,
            idempotencyKey: uuid(),
          })
          .pipe(
            tap(() =>
              this.notification.success('We are attempting to connect you to the driver. Please wait for the call'),
            ),
            map(_ => RidesActions.driverCalled()),
            catchError(error => {
              this.notification.error(error);
              return [RidesActions.driverCallFailed()];
            }),
          );
      }),
    ),
  );

  updatePickupTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.updatePickupTime),
      withLatestFrom(this.store.select(RidesSelectors.selectBookingAggregate)),
      switchMap(([action, bookingAggregate]) => {
        if (!bookingAggregate.booking.quote_id) {
          const updateBookingRequest: UpdateBookingRequest = {
            updateMask: 'localPickupAt',
            id: bookingAggregate.booking.id,
            localPickupAt: action.date,
          };
          return this.webApiService.updateBooking(updateBookingRequest).pipe(
            tap(() => this.notification.success('Pickup time updated')),
            switchMap(_ => [RidesActions.pickupTimeUpdated({ id: bookingAggregate.booking.id })]),
            catchError(error => {
              this.notification.error(error);
              return [RidesActions.pickupTimeUpdateFailed()];
            }),
          );
        } else {
          const quoteRequest: QuoteRequest = {
            businessId: bookingAggregate.booking.business_id,
            dropoff: {
              lat: bookingAggregate.booking.dropoff.lat,
              lng: bookingAggregate.booking.dropoff.lng,
              formattedAddress: bookingAggregate.booking.dropoff.formatted_address,
            },
            pickup: {
              lat: bookingAggregate.booking.pickup.lat,
              lng: bookingAggregate.booking.pickup.lng,
              formattedAddress: bookingAggregate.booking.pickup.formatted_address,
            },
            areaId: bookingAggregate.booking.area_id,
            vehicleTypeId: bookingAggregate.booking.vehicle_type_id,
            featureIds: bookingAggregate.booking.feature_ids,
            localPickupAt: this.dateHelperService.dateTimeToStringISO(new Date(action.date)),
            paymentMethod: bookingAggregate.booking.payment_method ?? { type: PaymentMethodType.IN_PERSON },
            packageId: bookingAggregate.booking.package_id,
          };
          return this.webApiService.quote(quoteRequest).pipe(
            switchMap(quoteResponse => {
              return combineLatest([
                this.firestoreService.watchQuote(quoteResponse.quote.id),
                this.firestoreService.watchQuote(bookingAggregate.booking.quote_id),
              ]).pipe(
                filter(([newQuote, oldQuote]) => !!newQuote && !!oldQuote),
                take(1),
                switchMap(([newQuote, oldQuote]) => {
                  const updateBookingRequest: UpdateBookingRequest = {
                    updateMask: 'quoteId,localPickupAt',
                    id: bookingAggregate.booking.id,
                    localPickupAt: action.date,
                    quoteId: newQuote.id,
                  };
                  if (oldQuote.total_price.value !== newQuote.total_price.value) {
                    this.dialog.openDialogs.forEach(dialog => dialog.close());
                    const dialogRef = this.dialog.open(ConfirmQuoteChangeDialogComponent, {
                      data: { oldQuote: oldQuote.total_price.display, newQuote: newQuote.total_price.display },
                    });
                    return dialogRef.componentInstance.modalAction$.pipe(
                      switchMap(result => {
                        if (result) {
                          return this.updatePickupTime(updateBookingRequest, bookingAggregate, newQuote, dialogRef);
                        } else {
                          this.notification.warn('Pickup time update canceled');
                          dialogRef.close();
                          return [RidesActions.pickupTimeUpdateCanceled()];
                        }
                      }),
                    );
                  } else {
                    const dialogRef = this.dialog.getDialogById('edit-pickup-time-dialog');
                    return this.updatePickupTime(updateBookingRequest, bookingAggregate, newQuote, dialogRef);
                  }
                }),
              );
            }),
            catchError(error => {
              this.notification.error(error);
              return [RidesActions.pickupTimeUpdateFailed()];
            }),
          );
        }
      }),
    ),
  );

  pickupTimeUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidesActions.pickupTimeUpdated),
      switchMap(_ => merge(this.watchDataSourceParameters())),
    ),
  );

  private updatePickupTime(
    updateBookingRequest: UpdateBookingRequest,
    bookingAggregate: BookingAggregate,
    newQuote: Quote,
    dialogRef?: MatDialogRef<EditPickupTimeDialogComponent | ConfirmQuoteChangeDialogComponent, unknown>,
  ):
    | Observable<Action<'[Rides] Pickup time update failed'> | ({ id: string } & Action<'[Rides] Pickup time updated'>)>
    | Action<'[Rides] Pickup time update canceled'>[] {
    return this.webApiService.updateBooking(updateBookingRequest).pipe(
      switchMap(_ => {
        return this.firestoreService.watchBookingAggregate(bookingAggregate.booking.id).pipe(
          filter(booking => booking.booking.quote_id === newQuote.id),
          take(1),
        );
      }),
      tap(() => {
        dialogRef?.close();
        this.notification.success('Pickup time updated');
      }),
      switchMap(_ => [RidesActions.pickupTimeUpdated({ id: bookingAggregate.booking.id })]),
      catchError(error => {
        this.notification.error(error);
        return [RidesActions.pickupTimeUpdateFailed()];
      }),
    );
  }

  private getPackage(aggregate: BookingAggregate) {
    if (!aggregate.booking.package_id) {
      return of(null);
    }
    return this.ridesFirestoreService.watchPackage(aggregate.booking.package_id).pipe(
      takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
      map(productPackage => productPackage),
    );
  }

  private getQuote(aggregate: BookingAggregate) {
    if (!aggregate.booking.quote_id) {
      return of(null);
    }
    return this.ridesFirestoreService.watchQuote(aggregate.booking.quote_id).pipe(
      takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
      map(quote => quote),
    );
  }

  private getDriver(aggregate: BookingAggregate) {
    if (aggregate.trip?.driver) {
      return of(aggregate.trip?.driver);
    }

    if (aggregate.job?.driver_id) {
      const rideStatus = getRideStatus(aggregate);
      if (rideStatus === RideStatus.IN_PROGRESS || rideStatus === RideStatus.ACCEPTED) {
        return combineLatest([
          this.firestoreService.watchDriver(aggregate.job.driver_id),
          this.firestoreService.watchDriverTelemetry(aggregate.job.driver_id),
        ]).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
          map(([driver, driverTelemetry]) => ({ ...driver, telemetry: driverTelemetry })),
        );
      }

      return this.firestoreService.watchDriver(aggregate.job.driver_id).pipe(
        takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
        map(driver => ({ ...driver, telemetry: null as DriverTelemetry })),
      );
    }

    return of(null);
  }

  private getVehicle(aggregate: BookingAggregate) {
    if (!aggregate.job?.vehicle_id) {
      return of(null);
    }
    return this.firestoreService.watchVehicle(aggregate.job.vehicle_id).pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
      map(vehicle => vehicle),
    );
  }

  private getVehicleType(aggregate: BookingAggregate) {
    if (!aggregate.booking.vehicle_type_id) {
      return of(null);
    }
    return this.firestoreService.watchVehicleType(aggregate.booking.vehicle_type_id).pipe(
      takeUntil(this.actions$.pipe(ofType(RidesActions.rideDeselected, RidesActions.ridesComponentDestroyed))),
      map(vehicleType => vehicleType),
    );
  }

  private watchDataSourceParameters(): Observable<Action> {
    return this.store.select(RidesSelectors.selectDataSourceParameters).pipe(switchMap(_ => this.loadRideAggregates()));
  }

  private loadRideAggregates() {
    return this.store.select(HomeSelectors.selectBusinessId).pipe(
      filter(businessId => !!businessId),
      take(1),
      concatLatestFrom(() => this.store.select(RidesSelectors.selectTableVM)),
      withLatestFrom(
        this.store.select(AuthSelectors.selectFcUserId),
        this.store.select(RidesSelectors.selectDataSourceParameters),
      ),
      switchMap(([[businessId, currentTableVM], businessSiteId, dataSourceParameters]) =>
        this.ridesFirestoreService
          .listRideAggregates(
            <string>businessId,
            <string>businessSiteId,
            {
              text: dataSourceParameters.text,
            },
            {
              pageSize: dataSourceParameters.pageSize,
              previousPageIndex: dataSourceParameters.previousPageIndex,
              pageIndex: dataSourceParameters.pageIndex,
              firstDocumentId:
                currentTableVM.rows && currentTableVM.rows.length > 0 ? currentTableVM.rows[0].bookingId : null,
              lastDocumentId:
                currentTableVM.rows && currentTableVM.rows.length > 0
                  ? currentTableVM.rows[currentTableVM.rows.length - 1].bookingId
                  : null,
            },
            dataSourceParameters.activeTab,
          )
          .pipe(
            switchMap(rides => {
              const packageList = [...new Set(rides.pageRows.map(ride => ride.booking.package_id).filter(id => !!id))];
              const vehicleList = [...new Set(rides.pageRows.map(ride => ride.job?.vehicle_id).filter(id => !!id))];
              const vehicleTypesList = [
                ...new Set(rides.pageRows.map(ride => ride.booking.vehicle_type_id).filter(id => !!id)),
              ];
              return combineLatest({
                quotes: this.ridesFirestoreService.listQuotes(rides.pageRows.map(ride => ride.booking.quote_id)).pipe(
                  whenPageVisible(),
                  takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
                  map(quotes => [...quotes.values()].filter(value => value !== null)),
                ),
                areas: this.ridesFirestoreService.listAreas().pipe(
                  whenPageVisible(),
                  takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
                  map(areas => [...areas.values()].filter(value => value !== null)),
                ),
                vehicleTypes: this.ridesFirestoreService
                  .listVehicleTypes(vehicleTypesList)
                  .pipe(whenPageVisible(), takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed)))),
                packages: packageList.length
                  ? this.ridesFirestoreService
                      .listPackages(packageList)
                      .pipe(
                        whenPageVisible(),
                        takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
                      )
                  : of([]),
                vehicles: vehicleList.length
                  ? this.ridesFirestoreService
                      .listVehicles(vehicleList)
                      .pipe(
                        whenPageVisible(),
                        takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
                      )
                  : of([]),
                rides: [rides],
              });
            }),
          ),
      ),
      map(result =>
        RidesActions.dataChanged({
          tableVM: this.buildTableVM(
            result.rides.pageRows,
            result.quotes,
            result.areas,
            result.vehicleTypes,
            result.packages,
            result.vehicles,
          ),
          totalCount: result.rides.totalCount,
          areas: result.areas,
        }),
      ),
    );
  }

  private buildTableVM(
    bookingAggregates: BookingAggregate[],
    quotes: Quote[],
    areas: Area[],
    vehicleTypes: VehicleType[],
    packages: Package[],
    vehicles: Vehicle[],
  ): RidesTableVM {
    return {
      rows: bookingAggregates.map(aggregate => {
        const area = areas.find(area => area.id === aggregate.booking.area_id);
        const quote = quotes.find(quote => quote.id === aggregate.booking.quote_id);
        const etaInMinutes = aggregate.pickup_eta?.eta ? Math.round(+aggregate.pickup_eta.eta / 60) : null;
        return <RideTableRow>{
          date: this.dateHelperService.numberToDate(aggregate.booking.created_at, area.time_zone_id),
          time: aggregate.booking.pickup_at
            ? this.dateHelperService.numberToTime(aggregate.booking.pickup_at, area.time_zone_id)
            : this.dateHelperService.numberToTime(aggregate.booking.created_at, area.time_zone_id),
          pickupDate: this.dateHelperService.numberToDate(aggregate.booking.pickup_at, area.time_zone_id),
          pickupTime: this.dateHelperService.numberToTime(aggregate.booking.pickup_at, area.time_zone_id),
          bookingId: aggregate.booking.id,
          pickup: StringUtils.splitOnFirstComma(
            aggregate.booking.pickup.formatted_address,
            aggregate.booking.pickup.name,
          ),
          dropoff: StringUtils.splitOnFirstComma(
            aggregate.booking.dropoff?.formatted_address,
            aggregate.booking.dropoff?.name,
          ),
          note: aggregate.booking.notes[0]?.body,
          phone: aggregate.booking.phone_number,
          price: quote?.total_price.display,
          status: getRideStatus(aggregate),
          eta: etaInMinutes,
          licensePlate: aggregate.trip?.vehicle?.license_plate,
          vehicleType: vehicleTypes.find(vt => vt.id === aggregate.booking.vehicle_type_id),
          package: packages.find(p => p.id === aggregate.booking.package_id),
          vehicle: vehicles.find(v => v.id === aggregate.job?.vehicle_id),
          paymentMethod: aggregate.booking?.payment_method?.type
            ? getPaymentMethodType(aggregate.booking.payment_method.type)
            : getPaymentMethodType(PaymentMethodType.IN_PERSON),
        };
      }),
    };
  }

  private watchPlatformSettings() {
    return this.firestoreService.watchPlatformSettings().pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
      map(settings => RidesActions.platformSettingsLoaded({ settings })),
      catchError(error => {
        this.notification.error(error);
        return of(RidesActions.platformSettingsLoadFailed());
      }),
    );
  }

  private watchAreas() {
    return this.firestoreService.watchAreas().pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(RidesActions.ridesComponentDestroyed))),
      map(areas => RidesActions.areasLoaded({ areas })),
      catchError(error => {
        this.notification.error(error);
        return of(RidesActions.areasLoadFailed());
      }),
    );
  }
}
