import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import { collection, Firestore, orderBy, query, where } from '@angular/fire/firestore';
import { FirestoreHelperService, Pagination } from '../../../shared/services/firestore-helper.service';
import { Area, BookingAggregate, Package, Quote, Vehicle, VehicleType } from '../../../shared/models/firestore.model';
import { and, doc, onSnapshot, or, QueryCompositeFilterConstraint, QueryNonFilterConstraint } from 'firebase/firestore';
import { RidesTab } from '../../../shared/models/rides.vm';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RidesFirestoreService {
  private firestore = inject(Firestore);
  private firestoreHelper = inject(FirestoreHelperService);
  private injectionContext = inject(EnvironmentInjector);

  watchBookingAggregate(id: string): Observable<BookingAggregate> {
    return new Observable(observer => {
      const docRef = doc(this.firestore, `booking_aggregate/${id}`);

      const unsubscribe = onSnapshot(
        docRef,
        snapshot => {
          const data = snapshot.data();
          observer.next(this.firestoreHelper.toSerializable(data) as BookingAggregate);
        },
        error => {
          observer.error(error);
        },
      );

      return () => unsubscribe();
    });
  }

  watchPackage(id: string) {
    return this.firestoreHelper.getDocument<Package>(`package`, id);
  }

  watchQuote(id: string) {
    return this.firestoreHelper.getDocument<Quote>(`quote`, id);
  }

  listQuotes(ids: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<Quote>('quote', ids);
  }

  listVehicleTypes(ids: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<VehicleType>('vehicle_type', ids);
  }

  listPackages(ids: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<Package>('package', ids);
  }

  listVehicles(ids: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<Vehicle>('vehicle', ids);
  }

  listAreas() {
    return runInInjectionContext(this.injectionContext, () => {
      const collectionPath = `area`;
      const colRef = collection(this.firestore, collectionPath);
      const q = query(colRef);

      return this.firestoreHelper.getDocumentsByQuery<Area>(q);
    });
  }

  listRideAggregates(
    businessId: string,
    businessSiteId: string,
    filter: { text: string | null },
    pagination: Pagination,
    activeTab: RidesTab,
  ) {
    return runInInjectionContext(this.injectionContext, () => {
      const collectionPath = `booking_aggregate`;
      const colRef = collection(this.firestore, collectionPath);

      let activeTabFilter: QueryCompositeFilterConstraint;
      let nonQueryConstraints: QueryNonFilterConstraint[] = [orderBy('booking.created_at', 'desc')];
      if (activeTab === ('Recent' as RidesTab)) {
        activeTabFilter = or(where('booking.pickup_at', '<', new Date()), where('booking.pickup_at', '==', null));
      } else {
        activeTabFilter = and(where('booking.pickup_at', '>=', new Date()));
        nonQueryConstraints = [orderBy('booking.pickup_at', 'asc')];
      }

      const constraints: QueryCompositeFilterConstraint = and(
        and(where('booking.business_id', '==', businessId)),
        and(where('booking.business_site_id', '==', businessSiteId)),
        and(activeTabFilter),
      );

      const q = query(colRef, constraints, ...nonQueryConstraints);

      return this.firestoreHelper.getDocsPageable<BookingAggregate>(q, collectionPath, pagination);
    });
  }
}
