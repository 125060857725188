import { PaymentMethodType } from 'src/app/shared/models/payment-methods.model';
import { Area, BookingAggregate, Location } from '../../../shared/models/firestore.model';
import { RideDetailsVM, RideStatus } from '../../../shared/models/rides.vm';
import { LatLng } from 'src/app/shared/models/latlng.model';

export function getRideStatus(aggregate: BookingAggregate): RideStatus {
  if (aggregate.booking.cancelled_at) {
    return RideStatus.CANCELLED;
  }

  if (aggregate.job?.completed_at) {
    return RideStatus.COMPLETED;
  }

  if (aggregate.job?.ended_at || aggregate.trip?.ended_at) {
    return RideStatus.ENDED;
  }

  if (aggregate.job?.in_progress_at) {
    return RideStatus.IN_PROGRESS;
  }

  if (aggregate.job?.accepted_at) {
    return RideStatus.ACCEPTED;
  }

  if (aggregate.booking?.expired_at) {
    return RideStatus.EXPIRED;
  }

  if (aggregate.booking?.pickup_at == null) {
    return RideStatus.LOOKING_FOR_DRIVER;
  }

  if (aggregate.booking.is_prebooking && aggregate.dispatch_trigger?.fired_at !== null) {
    return RideStatus.LOOKING_FOR_DRIVER;
  }

  if (isClaimed(aggregate)) {
    return RideStatus.CLAIMED;
  }

  if (aggregate.booking?.pickup_at) {
    return RideStatus.SCHEDULED;
  }

  return RideStatus.CREATED;
}

function isClaimed(aggregate: BookingAggregate): boolean {
  if (aggregate.claims?.length > 0) {
    return !![...aggregate.claims].sort((a, b) => b.meta_ts - a.meta_ts)[0].driver_id;
  }
  return false;
}

export function mapBookingAggregateToRideDetails(
  aggregate: BookingAggregate,
  areas: Area[],
  mapCenter?: LatLng,
): RideDetailsVM {
  const area = areas.find(a => a.id === aggregate.booking.area_id);
  return {
    id: aggregate.booking.id,
    createdAt: aggregate.booking.created_at,
    status: getRideStatus(aggregate),
    pickupAt: aggregate.booking.pickup_at,
    price: aggregate.trip_price?.customer_net_amount?.display || aggregate.quote?.total_price?.display,
    externalFeesAmount:
      aggregate.trip_price?.external_fees_amount?.display || aggregate.quote?.external_fees_amount?.display,
    pickupAddress: aggregate.booking.pickup?.formatted_address,
    pickupAddressName: aggregate.booking.pickup?.name,
    dropOffAddress: aggregate.booking.dropoff?.formatted_address,
    dropOffAddressName: aggregate.booking.dropoff?.name,
    pickupLocation: getLatLng(aggregate.booking.pickup),
    dropOffLocation: getLatLng(aggregate.booking.dropoff),
    polyline: aggregate.trip?.polyline || aggregate.quote?.polyline || null,
    paymentMethod: getPaymentMethodType(aggregate.booking.payment_method?.type),
    mapCenter,
    driver: aggregate.driver?.first_name || null,
    driverTelemetry: aggregate.driver?.telemetry,
    vehicle: getVehicle(aggregate),
    note: aggregate.booking.notes?.[0]?.body,
    phoneNumber: aggregate.booking.phone_number,
    package: aggregate.productPackage,
    quoteId: aggregate.booking.quote_id,
    vehicleType: aggregate.vehicleType,
    area,
  };
}

function getVehicle(aggregate: BookingAggregate): string | null {
  if (aggregate.vehicle) {
    return `${aggregate.vehicle?.license_plate} · ${aggregate.vehicle?.make} ${aggregate.vehicle?.model}`;
  }
  return null;
}

export function getLatLng(location: Location): LatLng | null {
  if (!location) {
    return null;
  }
  return {
    lat: location.lat,
    lng: location.lng,
  } as LatLng;
}

export function getPaymentMethodType(type: PaymentMethodType) {
  switch (type) {
    case PaymentMethodType.CARD:
      return 'Card';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_UNDEFINED:
      return 'Undefined';
    case PaymentMethodType.VEHICLE_CARD:
      return 'Vehicle card';
    case PaymentMethodType.VEHICLE_CASH:
      return 'Vehicle cash';
    case PaymentMethodType.IN_PERSON:
      return 'In person';
    case PaymentMethodType.INVOICE:
      return 'Invoice';
    case PaymentMethodType.PAYMENT_LINK:
      return 'Payment link';
    default:
      return 'In person';
  }
}
