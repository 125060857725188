/* eslint-disable  @typescript-eslint/no-empty-interface */
import { createReducer, on } from '@ngrx/store';
import * as UsersActions from './users.actions';

export const usersFeatureKey = 'users';

export interface UsersTableVM {
  rows: UserTableRow[] | null;
}

export interface UserTableRow {
  invitationId: string;
  businessProfileId: string;
  email: string;
  name: string;
  pendingInvite: boolean;
}

export interface State {
  isLoadingInvitations: boolean;
  isInvitingUser: boolean;
  isRemovingUser: boolean;
  isResendingInvite: boolean;
  isUserInvitationMenuOpened: boolean;
  isUserInvitationUploadMenuOpened: boolean;
  filter: {
    text: string;
  };
  tableVM: UsersTableVM;
  pagination: {
    pageSize: number;
    pageIndex: number;
    previousPageIndex: number | null;
  };
  totalRowsCount: number;
}

export const initialState: State = {
  isLoadingInvitations: false,
  isInvitingUser: false,
  isRemovingUser: false,
  isResendingInvite: false,
  isUserInvitationMenuOpened: false,
  isUserInvitationUploadMenuOpened: false,
  filter: {
    text: '',
  },
  tableVM: {
    rows: null,
  },
  pagination: {
    pageSize: 25,
    pageIndex: 0,
    previousPageIndex: undefined,
  },
  totalRowsCount: null,
};

export const reducer = createReducer(
  initialState,
  on(UsersActions.UsersComponentInit, (state, action) => ({
    ...state,
    isLoadingInvitations: true,
  })),
  on(UsersActions.inviteUserClicked, (state, action) => ({
    ...state,
    isInvitingUser: true,
  })),
  on(UsersActions.inviteUserFinished, (state, action) => ({
    ...state,
    isInvitingUser: false,
  })),
  on(UsersActions.removeUserInvitationConfirmed, (state, action) => ({
    ...state,
    isRemovingUser: true,
  })),
  on(UsersActions.removeRiderBusinessProfileConfirmed, (state, action) => ({
    ...state,
    isRemovingUser: true,
  })),
  on(UsersActions.removeUserFinished, (state, action) => ({
    ...state,
    isRemovingUser: false,
  })),
  on(UsersActions.resendInvitationClicked, (state, action) => ({
    ...state,
    isResendingInvite: true,
  })),
  on(UsersActions.resendInvitationFinished, (state, action) => ({
    ...state,
    isResendingInvite: false,
  })),
  on(UsersActions.userInvitationMenuOpenedChanged, (state, action) => ({
    ...state,
    isUserInvitationMenuOpened: action.opened,
    isUserInvitationUploadMenuOpened: false,
  })),
  on(UsersActions.dataChanged, (state, action) => ({
    ...state,
    tableVM: action.tableVM,
    isLoadingInvitations: false,
    totalRowsCount: action.totalCount,
  })),
  on(UsersActions.filterChanged, (state, action) => ({
    ...state,
    filter: action.filter,
    pagination: {
      pageSize: 25,
      pageIndex: 0,
      previousPageIndex: undefined,
    },
    isLoadingInvitations: true,
  })),
  on(UsersActions.pageEvent, (state, action) => ({
    ...state,
    pagination: {
      pageSize: action.pageSize,
      pageIndex: action.pageIndex,
      previousPageIndex: action.previousPageIndex,
    },
    isLoadingInvitations: true,
  })),
  on(UsersActions.userInvitationUploadMenuOpenedChanged, (state, action) => ({
    ...state,
    isUserInvitationUploadMenuOpened: action.opened,
    isUserInvitationMenuOpened: false,
  })),
);
