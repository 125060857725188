import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, isDevMode, ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { AngularFirebaseService } from './shared/services/angular-firebase.service';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore, setLogLevel } from '@angular/fire/firestore';
import { config } from 'src/environments/config';
import { DATE_LOCALE } from './shared/services/date-helper.service';
import { da, enUS, lt } from 'date-fns/locale';
import { Locale } from 'date-fns';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { registerLocaleData } from '@angular/common';
import localeLt from '@angular/common/locales/lt';
import localeDa from '@angular/common/locales/da';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';

export function initFirebase(firebase: AngularFirebaseService) {
  // setLogLevel('debug');
  return () => {
    firebase.initFirebase();
  };
}

// Supported locales
const Locales: { [key: string]: Locale } = {
  da: da,
  'en-US': enUS,
  lt: lt,
};

registerLocaleData(localeLt, Locales['lt']);
registerLocaleData(localeDa, Locales['da']);

function getSupportedLocale() {
  for (const lang of window.navigator.languages) {
    if (Locales[lang]) {
      return Locales[lang];
    }
  }
  return enUS;
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppStoreModule,
    BrowserAnimationsModule,
    CoreModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideDateFnsAdapter(),
    provideFirebaseApp(() => initializeApp(config.firebase)),
    provideFirestore(() => getFirestore()),
    { provide: MAT_DATE_LOCALE, useValue: getSupportedLocale() },
    provideAppInitializer(() => {
      const initializerFn = initFirebase(inject(AngularFirebaseService));
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DATE_LOCALE,
      useValue: getSupportedLocale(),
    },
    provideHttpClient(withInterceptorsFromDi()),
    [{ provide: ErrorHandler, useClass: GlobalErrorHandlerService }],
  ],
})
export class AppModule {}
