/* eslint-disable  @typescript-eslint/no-empty-interface */
import { createReducer, on } from '@ngrx/store';
import * as RidesActions from './rides.actions';
import { RidesTab } from '../../../shared/models/rides.vm';
import { Area, BookingAggregate, PlatformSettings } from '../../../shared/models/firestore.model';

export const ridesFeatureKey = 'rides';

export interface RidesTableVM {
  rows: RideTableRow[] | null;
}

export interface RideTableRow {
  date: string;
  time: string;
  pickupDate?: string;
  pickupTime?: string;
  bookingId: string;
  pickup: [string, string] | null;
  dropoff?: [string, string] | null;
  phone: string;
  price: string;
}

export interface State {
  isLoadingRides: boolean;
  isLoadingRideDetails: boolean;
  isTrackingLinkSending: boolean;
  isCallingDriver: boolean;
  isConfirmWithNewQuoteInProgress: boolean;
  activeTab: RidesTab;
  selectedRideId: string | null;
  bookingAggregate: BookingAggregate | null;
  filter: {
    text: string;
  };
  tableVM: RidesTableVM;
  pagination: {
    pageSize: number;
    pageIndex: number;
    previousPageIndex?: number | undefined;
  };
  totalRowsCount: number;
  isCancelBookingInProgress: boolean;
  isUpdatePickupTimeInProgress: boolean;
  settings: PlatformSettings;
  areaId: string | null;
  areas: Area[];
}

export const initialState: State = {
  isLoadingRides: false,
  isLoadingRideDetails: false,
  isCancelBookingInProgress: false,
  isUpdatePickupTimeInProgress: false,
  isConfirmWithNewQuoteInProgress: false,
  isTrackingLinkSending: false,
  isCallingDriver: false,
  activeTab: 'Recent' as RidesTab,
  selectedRideId: null,
  bookingAggregate: null,
  filter: {
    text: '',
  },
  tableVM: {
    rows: null,
  },
  pagination: {
    pageSize: 25,
    pageIndex: 0,
    previousPageIndex: undefined,
  },
  totalRowsCount: null,
  settings: null,
  areaId: null,
  areas: [],
};

export const reducer = createReducer(
  initialState,
  on(RidesActions.ridesComponentInit, (state, _) => ({
    ...state,
    isLoadingRides: true,
  })),
  on(RidesActions.dataChanged, (state, action) => ({
    ...state,
    tableVM: action.tableVM,
    isLoadingRides: false,
    totalRowsCount: action.totalCount,
  })),
  on(RidesActions.filterChanged, (state, action) => ({
    ...state,
    filter: {
      text: action.filter.text,
    },
    pagination: {
      pageSize: 25,
      pageIndex: 0,
      previousPageIndex: undefined,
    },
    isLoadingRides: true,
  })),
  on(RidesActions.tabChanged, (state, action) => ({
    ...state,
    activeTab: action.tab,
    tableVM: {
      rows: null,
    },
    pagination: {
      pageSize: 25,
      pageIndex: 0,
      previousPageIndex: undefined,
    },
    isLoadingRides: true,
  })),
  on(RidesActions.pageEvent, (state, action) => ({
    ...state,
    pagination: {
      pageSize: action.pageSize,
      pageIndex: action.pageIndex,
      previousPageIndex: action.previousPageIndex,
    },
    isLoadingRides: true,
  })),
  on(RidesActions.rideSelected, (state, action) => ({
    ...state,
    selectedRideId: action.id,
    isLoadingRideDetails: true,
  })),
  on(RidesActions.rideDeselected, (state, _) => ({
    ...state,
    selectedRideId: null,
    bookingAggregate: null,
    isLoadingRideDetails: false,
  })),
  on(RidesActions.rideDetailsChanged, (state, action) => ({
    ...state,
    isLoadingRideDetails: false,
    bookingAggregate: action.aggregate,
    areaId: action.aggregate?.booking?.area_id,
  })),
  on(RidesActions.bookingCancelClicked, (state, _) => ({
    ...state,
    isCancelBookingInProgress: true,
  })),
  on(RidesActions.bookingCancelled, (state, _) => ({
    ...state,
    isCancelBookingInProgress: false,
  })),
  on(RidesActions.bookingCancelFailed, (state, _) => ({
    ...state,
    isCancelBookingInProgress: false,
  })),
  on(RidesActions.sendTrackingLinkClicked, (state, _) => ({
    ...state,
    isTrackingLinkSending: true,
  })),
  on(RidesActions.trackingLinkSent, (state, _) => ({
    ...state,
    isTrackingLinkSending: false,
  })),
  on(RidesActions.sendTrackingLinkFailed, (state, _) => ({
    ...state,
    isTrackingLinkSending: false,
  })),
  on(RidesActions.callDriverClicked, (state, _) => ({
    ...state,
    isCallingDriver: true,
  })),
  on(RidesActions.driverCalled, (state, _) => ({
    ...state,
    isCallingDriver: false,
  })),
  on(RidesActions.driverCallFailed, (state, _) => ({
    ...state,
    isCallingDriver: false,
  })),
  on(RidesActions.updatePickupTime, (state, _) => ({
    ...state,
    isUpdatePickupTimeInProgress: true,
  })),
  on(RidesActions.pickupTimeUpdated, (state, _) => ({
    ...state,
    isUpdatePickupTimeInProgress: false,
    isConfirmWithNewQuoteInProgress: false,
  })),
  on(RidesActions.pickupTimeUpdateFailed, (state, _) => ({
    ...state,
    isUpdatePickupTimeInProgress: false,
    isConfirmWithNewQuoteInProgress: false,
  })),
  on(RidesActions.pickupTimeUpdateCanceled, (state, _) => ({
    ...state,
    isUpdatePickupTimeInProgress: false,
    isConfirmWithNewQuoteInProgress: false,
  })),
  on(RidesActions.updateWithNewQuoteConfirmClicked, (state, _) => ({
    ...state,
    isConfirmWithNewQuoteInProgress: true,
  })),
  on(RidesActions.areasLoaded, (state, action) => {
    return {
      ...state,
      areas: action.areas,
    };
  }),
  on(RidesActions.platformSettingsLoaded, (state, action) => {
    return {
      ...state,
      settings: action.settings,
    };
  }),
);
