/* eslint-disable  @typescript-eslint/no-empty-interface */

import { LatLng } from './latlng.model';
import { PaymentMethod } from './payment-methods.model';
import { LocationVM } from './location.vm';

interface ApiRequest {
  idempotencyKey?: string;
}

export interface SignInRequest extends ApiRequest {
  email: string;
  password: string;
}

export interface SignInResponse {
  token: string;
}

export interface SignUpRequest extends ApiRequest {
  email: string;
  password: string;
}

export interface SignUpResponse {
  user: User;
  token: string;
}

export interface User {
  id: string;
}

export interface SendEmailVerificationRequest extends ApiRequest {}

export interface SendPasswordResetEmailRequest extends ApiRequest {
  email: string;
}

export interface CreateBusinessRequest extends ApiRequest {
  countryCode: string;
  name: string;
  companyCode: string;
  companySize: string;
}

export interface CreateBusinessResponse {
  business: Business;
}

export interface Business {
  id: string;
}

export interface CreateIntercomUserHashRequest extends ApiRequest {}

export interface CreateIntercomUserHashResponse {
  userHash: string;
}

export interface CreateRiderInvitationRequest extends ApiRequest {
  businessId: string;
  email: string;
}

export interface CreateRiderInvitationResponse {
  invitation: RiderInvitation;
}

export interface RiderInvitation {
  id: string;
}

export interface DeleteRiderInvitationRequest extends ApiRequest {
  id: string;
}

export interface DeleteRiderBusinessProfileRequest extends ApiRequest {
  id: string;
}

export interface ResendRiderInvitationRequest extends ApiRequest {
  id: string;
}

export interface BulkCreateRiderInvitationRequest extends ApiRequest {
  businessId: string;
  data: string;
}

export interface BulkCreateRiderInvitationResponse {
  result: BulkCreateResult;
  createdCount: number;
  existingCount: number;
  invalidEmails: string[];
}

export enum BulkCreateResult {
  BULK_CREATE_RESULT_UNDEFINED = 'BULK_CREATE_RESULT_UNDEFINED',
  BULK_CREATE_RESULT_SUCCESS = 'BULK_CREATE_RESULT_SUCCESS',
  BULK_CREATE_RESULT_INVALID = 'BULK_CREATE_RESULT_INVALID',
}

export interface CreateCustomTokenResponse {
  token: string;
}

export interface AutoCompleteRequest {
  input: string;
  location?: LatLng;
  language?: string;
  sources?: string[];
  radius?: number;
  sessionToken?: string;
}

export interface AutoCompleteResponse {
  predictions: AutoCompletePrediction[];
  source: AutoCompleteSource;
}

export type AutoCompleteSource = 'SOURCE_TYPE_PELIAS' | 'SOURCE_TYPE_GOOGLE';

export interface AutoCompletePrediction {
  placeId: string;
  description: string;
  location?: LatLng;
  name?: string;
  formattedAddress?: string;
}

export interface QuoteRequest {
  businessId: string;
  dropoff: Location;
  pickup: Location;
  areaId: string;
  vehicleTypeId: string;
  featureIds: string[];
  localPickupAt?: string;
  paymentMethod: PaymentMethod;
  packageId: string;
}

export interface QuoteResponse {
  quote: Quote;
}

export interface Quote {
  id: string;
}

export interface Location {
  lat: number;
  lng: number;
  formattedAddress: string;
}

export interface AreaLookupRequest {
  lat: number;
  lng: number;
}

export interface AreaLookupResponse {
  area: Area;
}

export interface Area {
  id: string;
}

export interface CreateBookingRequest {
  dropoff: LocationVM;
  pickup: Location;
  packageId: string;
  vehicleTypeId: string;
  featureIds: string[];
  quoteId?: string;
  paymentMethod: PaymentMethod;
  note?: string;
  phoneNumber?: string;
  businessId: string;
  businessSiteId: string;
  localPickupAt?: string;
}

export interface CreateBookingResponse {
  booking: Booking;
}

export interface CancelBookingRequest {
  id: string;
  userId: string;
  idempotenceKey?: string;
}

export interface UpdateBookingRequest {
  id: string;
  localPickupAt?: string;
  phoneNumber?: string;
  quoteId?: string;
  updateMask: string;
}

export interface Booking {
  id: string;
}

export interface LookupNearbyDriversRequest {
  location: LatLng;
  vehicleTypeId: string;
  featureIds: string[];
  idempotencyKey?: string;
  nearbyDriversLimit?: number;
}

export interface LookupNearbyDriversResponse {
  areaId: string;
  eta: string;
  nearbyDrivers: NearbyDriver[];
}

export interface NearbyDriver {
  distance: string;
  driverId: string;
  duration: string;
}

export interface LogEntriesRequest {
  logs: LogEntry[];
}

export interface LogEntry {
  level: LogLevel;
  message: string;
  timestamp: number;
  appName: string;
  appVersion: string;
  properties: Record<string, unknown>;
}

export enum LogLevel {
  LOG_LEVEL_UNDEFINED = 'LOG_LEVEL_UNDEFINED',
  LOG_LEVEL_DEBUG = 'LOG_LEVEL_DEBUG',
  LOG_LEVEL_INFO = 'LOG_LEVEL_INFO',
  LOG_LEVEL_WARNING = 'LOG_LEVEL_WARNING',
  LOG_LEVEL_ERROR = 'LOG_LEVEL_ERROR',
}

export interface CallDriverRequest {
  agentId: string;
  bookingId: string;
  callerPhoneNumber: string;
  idempotencyKey: string;
}
