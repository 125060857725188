import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
  private snackBar = inject(MatSnackBar);

  error(error: HttpErrorResponse | string, action = 'Close') {
    const message = this.parseError(error);
    return this.snackBar.open(message, action, {
      panelClass: 'error',
      verticalPosition: 'top',
    });
  }

  success(message: string, action = 'Close') {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: 'success',
      verticalPosition: 'top',
    });
  }

  warn(message: string, action = 'Close', duration = 3000) {
    return this.snackBar.open(message, action, {
      duration,
      panelClass: 'warn',
      verticalPosition: 'top',
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }

  private parseError(error: HttpErrorResponse | string) {
    if (typeof error === 'string') {
      return error;
    }
    const message = error?.error?.message;
    return message?.split('|').pop() || message || 'Unknown error occurred. Please try again.';
  }
}
