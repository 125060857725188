import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import {
  collectionData,
  doc,
  docData,
  docSnapshots,
  DocumentData,
  DocumentSnapshot,
  endBefore,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  Query,
  query,
  QueryConstraint,
  startAfter,
  startAt,
  Timestamp,
} from '@angular/fire/firestore';
import { combineLatest, forkJoin, from, map, Observable, of, switchMap, take } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface Pagination {
  pageSize: number;
  previousPageIndex?: number;
  pageIndex: number;
  firstDocumentId: string | null;
  lastDocumentId: string | null;
}

@Injectable({ providedIn: 'root' })
export class FirestoreHelperService {
  private injectionContext = inject(EnvironmentInjector);
  private firestore = inject(Firestore);

  /**
   * Get one document
   * @param collectionPath
   * @param documentId
   */
  getDocument<T>(collectionPath: string, documentId: string): Observable<T | null> {
    return runInInjectionContext(this.injectionContext, () => {
      const docRef = doc(this.firestore, collectionPath, documentId);
      return from(
        getDoc(docRef)
          .then(docSnap => {
            if (docSnap.exists()) {
              return {
                id: docSnap.id,
                ...this.toSerializable(docSnap.data()),
              } as T;
            } else {
              return null;
            }
          })
          .catch(error => {
            const customError = error as Error;
            customError.stack = `Error fetching document ${collectionPath}/${documentId} - ${error.message} at ${error.stack}`;
            throw customError;
          }),
      );
    });
  }

  /**
   * Get multiple documents by ids
   * @param collectionPath
   * @param documentIds
   */
  getDocumentsByIds<T>(collectionPath: string, documentIds: string[]): Observable<Map<string, T | null>> {
    return runInInjectionContext(this.injectionContext, () => {
      const fetchObservables = documentIds.map(documentId => {
        const docRef = doc(this.firestore, collectionPath, documentId);
        if (!docRef) {
          return of({ id: documentId, data: null });
        }
        return from(getDoc(docRef)).pipe(
          map(docSnap => {
            const data = docSnap.exists() ? (this.toSerializable(docSnap.data()) as T) : null;
            return { id: documentId, data };
          }),
        );
      });

      return forkJoin(fetchObservables).pipe(
        map(results => {
          const resultMap = new Map<string, T | null>();
          results.forEach(result => {
            resultMap.set(result.id, result.data);
          });
          return resultMap;
        }),
      );
    });
  }

  /**
   * Get multiple documents by query
   * @param q Query
   * @returns Serializable map of id - document
   */
  getDocumentsByQuery<T>(q: Query): Observable<Map<string, T | null>> {
    return runInInjectionContext(this.injectionContext, () => {
      return from(getDocs(q)).pipe(
        map(data => {
          const resultMap = new Map<string, T>();
          data.docs.forEach(doc => {
            resultMap.set(
              doc.id,
              this.toSerializable({
                id: doc.id,
                ...doc.data(),
              }) as T,
            );
          });
          return resultMap;
        }),
      );
    });
  }

  /**
   * Wrapper for docData that ensures serializability
   * @param path Path to document
   * @returns Serializable stream
   */
  docData<T>(path: string) {
    return runInInjectionContext(this.injectionContext, () => {
      const docRef = doc(this.firestore, path);
      return docData(docRef, { idField: 'id' }).pipe(
        map(data => {
          return (this.toSerializable(data) as T) || null;
        }),
        catchError(error => {
          const customError = error as Error;
          customError.stack = `Error fetching documents from ${path} - ${error.message} at ${error.stack}`;
          throw customError;
        }),
      );
    });
  }

  /**
   * Wrapper for colData that ensures serializability
   * @param query Query
   * @returns Serializable stream
   */
  colData<T>(query: Query) {
    return runInInjectionContext(this.injectionContext, () => {
      return collectionData(query, { idField: 'id' }).pipe(
        map(data => {
          return data.map(item => this.toSerializable(item) as T);
        }),
      );
    });
  }

  /**
   * Collection data with paging
   * @param q Query
   * @param collectionPath string
   * @param pagination Pagination
   * @returns Serializable data with pagination parameters
   */
  colDataPageable<T>(q: Query, collectionPath: string, pagination: Pagination) {
    return this.getPaginationConstraints(collectionPath, pagination).pipe(
      switchMap(constraints => {
        return runInInjectionContext(this.injectionContext, () => {
          const paginatedQuery = query(q, ...constraints);
          return collectionData(paginatedQuery, { idField: 'id' }).pipe(
            switchMap(data => {
              return from(getCountFromServer(q)).pipe(
                map(countSnapshot => {
                  return {
                    pageRows: data.map(item => this.toSerializable(item) as T),
                    totalCount: countSnapshot.data().count,
                  };
                }),
              );
            }),
          );
        });
      }),
    );
  }

  /**
   * Get documents with paging
   * @param q Query
   * @param pagination Pagination
   * @returns Serializable data with pagination parameters
   */
  getDocsPageable<T>(q: Query, collectionPath: string, pagination: Pagination) {
    return this.getPaginationConstraints(collectionPath, pagination).pipe(
      switchMap(constraints => {
        return runInInjectionContext(this.injectionContext, () => {
          const paginatedQuery = query(q, ...constraints);
          return from(getDocs(paginatedQuery)).pipe(
            map(data => {
              return data.docs.map(doc => {
                return this.toSerializable({
                  id: doc.id,
                  ...doc.data(),
                }) as T;
              });
            }),
            switchMap(data => {
              return runInInjectionContext(this.injectionContext, () => {
                return from(getCountFromServer(q)).pipe(
                  map(countSnapshot => {
                    return {
                      pageRows: data,
                      totalCount: countSnapshot.data().count,
                    };
                  }),
                );
              });
            }),
          );
        });
      }),
    );
  }

  /**
   * Watch multiple documents by ids, ignore non existing ones
   * @param colPath Collection path
   * @param ids Document ids
   * @returns Serializable stream of all existing documents
   */
  colDataByIdsNotNull<T>(colPath: string, ids: string[]): Observable<T[]> {
    if (!ids?.length) {
      return of([]);
    }

    return combineLatest(ids.map(id => this.docData<T>(`${colPath}/${id}`))).pipe(
      map(data => {
        return data.filter(item => !!item) as T[];
      }),
    );
  }

  /**
   * Watch multiple documents by ids
   * @param colPath Collection path
   * @param ids Document ids
   * @returns Map of id - serializable document (or null if non existing)
   */
  colDataByIds<T>(colPath: string, ids: string[]): Observable<Map<string, T | null>> {
    if (!ids?.length) {
      return of(new Map<string, T | null>());
    }

    return runInInjectionContext(this.injectionContext, () => {
      return combineLatest(ids.map(id => this.docData<T>(`${colPath}/${id}`))).pipe(
        map(data => {
          return new Map(data.map((item, index) => [ids[index], item]));
        }),
      );
    });
  }

  toSerializable(obj: DocumentData) {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }

    const result: DocumentData = {};
    Object.keys(obj).forEach(key => {
      const val = obj[key];
      if (val instanceof Timestamp) {
        result[key] = val.toMillis();
      } else if (val instanceof Array) {
        result[key] = val.map(item => this.toSerializable(item));
      } else if (typeof val === 'object') {
        result[key] = this.toSerializable(val);
      } else {
        result[key] = val;
      }
    });
    return result;
  }

  private getPaginationConstraints(collectionPath: string, pagination: Pagination): Observable<QueryConstraint[]> {
    const getConstraints = (
      cursorDocId: string | null,
      limitFn: (count: number) => QueryConstraint,
      cursorConstraintFn: (snapshot: DocumentSnapshot) => QueryConstraint,
    ) => {
      const limitConstraints: QueryConstraint[] = [limitFn(pagination.pageSize)];
      if (!cursorDocId) {
        return of(limitConstraints);
      }
      return runInInjectionContext(this.injectionContext, () => {
        return docSnapshots(doc(this.firestore, `${collectionPath}/${cursorDocId}`)).pipe(
          take(1),
          map(documentSnapshot => {
            return runInInjectionContext(this.injectionContext, () => {
              return documentSnapshot.exists()
                ? [...limitConstraints, cursorConstraintFn(documentSnapshot)]
                : limitConstraints;
            });
          }),
        );
      });
    };

    return runInInjectionContext(this.injectionContext, () => {
      if (pagination.previousPageIndex === undefined) {
        // first page
        return of([limit(pagination.pageSize) as QueryConstraint]);
      } else if (pagination.previousPageIndex === pagination.pageIndex) {
        // same page
        return getConstraints(pagination.firstDocumentId, limit, startAt);
      } else if (pagination.previousPageIndex < pagination.pageIndex) {
        // next page
        return getConstraints(pagination.lastDocumentId, limit, startAfter);
      } else if (pagination.previousPageIndex > pagination.pageIndex) {
        // previous page
        return getConstraints(pagination.firstDocumentId, limitToLast, endBefore);
      }
      throw new Error('Unexpected pagination parameters.', { cause: pagination });
    });
  }
}
