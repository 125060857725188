import { ErrorHandler, Injectable, inject } from '@angular/core';
import { B2BWebApiService } from './b2b-web-api.service';
import { LogEntriesRequest, LogEntry, LogLevel } from '../models/b2b-web-api.model';
import { config } from '../../../environments/config';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionTrackerService } from './action-tracker.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  private webApi = inject(B2BWebApiService);
  private actionTrackerService = inject(ActionTrackerService);

  private readonly appName = 'B2BWebApp';
  private readonly appVersion = config.appVersion;

  handleError(err: unknown): void {
    const errorDetails: ErrorMessage = {};

    if (err instanceof HttpErrorResponse) {
      errorDetails.message = `HTTP Error: ${err.message}`;
      errorDetails.url = err.url;
      errorDetails.status = err.status;
    } else if (err instanceof Error) {
      errorDetails.message = `General Error: ${err.message}`;
      errorDetails.stack = err.stack;
    } else {
      errorDetails.message = 'Unknown Error: An unexpected error occurred';
      errorDetails.rawError = JSON.stringify(err);
    }

    const properties: Record<string, string> = {};
    properties['userActions'] = JSON.stringify(this.actionTrackerService.getActionHistory());

    console.error(errorDetails.message);
    this.postLog([this.format(errorDetails, LogLevel.LOG_LEVEL_ERROR, properties)]);

    throw err;
  }

  private postLog(logs: LogEntry[]): void {
    this.webApi.log({ logs } as LogEntriesRequest).subscribe({
      error: err => console.error('Error logging to API', err),
    });
  }

  private format(detail: ErrorMessage, level: LogLevel, properties?: Record<string, string>): LogEntry {
    return {
      timestamp: Date.now(),
      appName: this.appName,
      appVersion: this.appVersion,
      level,
      message: `${detail.message} at ${detail.stack ?? detail.url ?? detail.rawError}`,
      properties,
    };
  }
}

interface ErrorMessage {
  message?: string;
  url?: string;
  stack?: string;
  rawError?: string;
  status?: number;
}
