import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of, withLatestFrom } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirebaseService } from 'src/app/shared/services/angular-firebase.service';
import { B2BWebApiService } from 'src/app/shared/services/b2b-web-api.service';
import * as AuthActions from './auth.actions';
import { uuid } from 'src/app/shared/utils/uuid';
import { RouterActions } from 'src/app/shared/store/modules/router';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import { AuthSelectors } from '.';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private api = inject(B2BWebApiService);
  private firebase = inject(AngularFirebaseService);
  private store = inject(Store);
  private notification = inject(NotificationService);
  private firestore = inject(FirestoreService);
  private breakpointObserver = inject(BreakpointObserver);
  private router = inject(Router);

  observeBreakpoints$ = createEffect(() =>
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(map(result => AuthActions.layoutHandsetChanged({ isHandset: result.matches }))),
  );

  loginButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginButtonClicked),
      switchMap(action =>
        this.api.signIn({ email: action.email, password: action.password, idempotencyKey: uuid() }).pipe(
          switchMap(response => this.firebase.signInWithCustomToken(response.token)),
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      switchMap(result =>
        result
          ? [AuthActions.loginFinished(), RouterActions.navigateByUrlAction({ url: '/home' })]
          : [AuthActions.loginFinished()],
      ),
    ),
  );

  registerButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signUpButtonClicked),
      switchMap(action =>
        this.api.signUp({ email: action.email, password: action.password }).pipe(
          switchMap(response =>
            this.firebase
              .signInWithCustomToken(response.token)
              .pipe(switchMap(_ => this.api.sendEmailverification({}))),
          ),
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      switchMap(_ => [AuthActions.signUpFinished()]),
    ),
  );

  createBusinessButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.createBusinessButtonClicked),
      switchMap(action =>
        this.api
          .createBusiness({
            countryCode: action.countryCode,
            name: action.name,
            companyCode: action.companyCode,
            companySize: action.companySize,
          })
          .pipe(
            switchMap(_ =>
              this.store.select(AuthSelectors.selectExternalAccountManagerAggregate).pipe(
                filter(aggregate => !!aggregate?.external_account_manager?.business_id),
                take(1),
              ),
            ),
            catchError(error => {
              this.notification.error(error);
              return of(null);
            }),
          ),
      ),
      switchMap(result =>
        result
          ? [AuthActions.createBusinessFinished(), RouterActions.navigateByUrlAction({ url: '/home' })]
          : [AuthActions.createBusinessFinished()],
      ),
    ),
  );

  logoutClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutClicked),
      switchMap(_ =>
        this.firebase.logout().pipe(
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      switchMap(_ => [RouterActions.navigateByUrlAction({ url: '/auth/login' })]),
    ),
  );

  passwordResetButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.passwordResetButtonClicked),
      switchMap(action =>
        this.api.sendPasswordResetEmail({ email: action.email, idempotencyKey: uuid() }).pipe(
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      map(result => {
        if (result) {
          this.notification.success('Reset link has been sent to your email.');
          return AuthActions.passwordResetSuccess();
        } else {
          return AuthActions.passwordResetFailure();
        }
      }),
    ),
  );

  sendEmailVerificationButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.sendEmailVerificationButtonClicked),
        switchMap(_ =>
          this.api.sendEmailverification({ idempotencyKey: uuid() }).pipe(
            catchError(error => {
              this.notification.error(error);
              return of(null);
            }),
          ),
        ),
        tap(result => {
          if (result) {
            this.notification.success('Verification email has been sent to your email.');
          }
        }),
      ),
    { dispatch: false },
  );

  tokenChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.tokenChanged),
      withLatestFrom(
        this.store.select(AuthSelectors.selectUid),
        this.store.select(AuthSelectors.selectUserRoles),
        this.store.select(AuthSelectors.selectFcUserId),
      ),
      filter(([_action, userId, roles, _fcUserId]) => !!userId && roles?.length > 0),
      switchMap(([_action, userId, roles, fcUserId]) => {
        const appConfig$ = this.watchAppConfig();
        const agentAggregate$ =
          roles.includes('b2b-web.agent') && fcUserId ? this.watchAgentAggregate(fcUserId) : of(null);
        const externalAccountManagerAggregate$ = roles.includes('b2b-web.external_account_manager')
          ? this.watchExternalAccountManagerAggregate(userId)
          : of(null);
        return merge(
          appConfig$,
          agentAggregate$.pipe(filter(aggregate => !!aggregate)),
          externalAccountManagerAggregate$.pipe(filter(aggregate => !!aggregate)),
        );
      }),
    ),
  );

  private watchExternalAccountManagerAggregate(userId: string) {
    return this.firestore.watchExternalAccountManagerAggregate(userId).pipe(
      takeUntil(this.store.select(AuthSelectors.selectLoggedIn).pipe(filter(loggedIn => !loggedIn))),
      map(aggregate =>
        AuthActions.externalAccountManagerAggregateChanged({ externalAccountManagerAggregate: aggregate }),
      ),
    );
  }

  private watchAgentAggregate(agentId: string) {
    return this.firestore.watchAgentAggregate(agentId).pipe(
      takeUntil(this.store.select(AuthSelectors.selectLoggedIn).pipe(filter(loggedIn => !loggedIn))),
      map(agentAggregate => AuthActions.agentAggregateChanged({ agentAggregate })),
    );
  }

  private watchAppConfig() {
    return this.firestore.watchAppConfig().pipe(
      takeUntil(this.store.select(AuthSelectors.selectLoggedIn).pipe(filter(loggedIn => !loggedIn))),
      map(appConfig => AuthActions.appConfigChanged({ appConfig })),
    );
  }

  agentAggregateChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.agentAggregateChanged),
        withLatestFrom(
          this.store.select(AuthSelectors.selectIsRidesMenuIsVisible),
          this.store.select(AuthSelectors.selectIsExternalAccountManager),
        ),
        filter(([_action, isVisible, isExternalAccountManager]) => isVisible && !isExternalAccountManager),
        tap(() => this.router.navigate(['/home/book-ride'])),
      ),
    { dispatch: false },
  );
}
