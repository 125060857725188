import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { RouterActions, RouterSelectors } from '.';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class RouterEffects {
  private actions$ = inject(Actions);
  private router = inject(Router);
  private store = inject(Store);
  private notification = inject(NotificationService);

  navigateByUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigateByUrlAction),
        tap(action => this.router.navigateByUrl(action.url, action.navigationExtras)),
      ),
    { dispatch: false },
  );

  routeChanged$ = createEffect(
    () => this.store.select(RouterSelectors.selectUrl).pipe(tap(_ => this.notification.dismiss())),
    { dispatch: false },
  );
}
