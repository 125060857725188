import { createReducer, on } from '@ngrx/store';
import { LocationVM, SuggestionsVM } from '../../../shared/models/location.vm';
import { BookRideActions } from '../store';
import {
  Package,
  Quote,
  VehicleType,
  Feature,
  BookingAggregate,
  PlatformSettings,
  Area,
  BusinessNote,
  DriverTelemetry,
} from '../../../shared/models/firestore.model';
import { PaymentMethodTypeVM } from '../../../shared/models/business.vm';

export const bookRideFeatureKey = 'bookRide';

export interface AreaState {
  areaId: string;
  packages: { items: Package[]; loaded: boolean };
  features: { items: Feature[]; loaded: boolean };
  vehicleTypes: { items: VehicleType[]; loaded: boolean };
}

export interface State {
  isLoading: boolean;
  isLoadingQuote: boolean;
  isLoadingPickupSuggestions: boolean;
  isLoadingDropoffSuggestions: boolean;
  isLoadingRideDetails: boolean;
  isTrackingLinkSending: boolean;
  isCallingDriver: boolean;
  isCancelBookingInProgress: boolean;
  areas: Area[];
  areaState: AreaState;
  packageId: string | null;
  vehicleTypeId: string;
  featureIds: string[];
  templateId: string;
  pickupLocation: LocationVM | null;
  pickupSuggestions: SuggestionsVM[];
  dropoffLocation: LocationVM;
  dropoffSuggestions: SuggestionsVM[];
  quote: Quote | null;
  upcomingRides: BookingAggregate[];
  settings: PlatformSettings;
  localPickupAtDateTime: string;
  paymentMethodType: PaymentMethodTypeVM;
  selectedRideAreaId: string;
  selectedRideBookingAggregate: BookingAggregate;
  rideRoute: string | null;
  isPrebooking: boolean;
  selectedRideId: string;
  businessNote: BusinessNote;
  eta: string;
  allPackages: Package[];
  allVehicleTypes: VehicleType[];
  nearbyDriversTelemetries: DriverTelemetry[];
}

export const initialState: State = {
  isLoading: false,
  isLoadingQuote: false,
  isLoadingPickupSuggestions: false,
  isLoadingDropoffSuggestions: false,
  isLoadingRideDetails: false,
  isTrackingLinkSending: false,
  isCallingDriver: false,
  isCancelBookingInProgress: false,
  areas: [],
  pickupLocation: null,
  pickupSuggestions: [],
  areaState: {
    areaId: null,
    packages: { items: [], loaded: false },
    features: { items: [], loaded: false },
    vehicleTypes: { items: [], loaded: false },
  },
  packageId: null,
  vehicleTypeId: null,
  featureIds: [],
  dropoffLocation: null,
  templateId: null,
  dropoffSuggestions: [],
  quote: null,
  upcomingRides: [],
  settings: null,
  localPickupAtDateTime: null,
  paymentMethodType: null,
  selectedRideAreaId: null,
  selectedRideBookingAggregate: null,
  rideRoute: null,
  isPrebooking: false,
  selectedRideId: null,
  businessNote: null,
  eta: null,
  allPackages: [],
  allVehicleTypes: [],
  nearbyDriversTelemetries: [],
};

export const reducer = createReducer(
  initialState,
  on(BookRideActions.createFormDestroyed, (state): State => {
    return {
      ...state,
      pickupLocation: null,
      dropoffLocation: null,
      packageId: null,
      vehicleTypeId: null,
      featureIds: null,
      quote: null,
      templateId: null,
      areaState: {
        areaId: null,
        packages: { items: [], loaded: false },
        features: { items: [], loaded: false },
        vehicleTypes: { items: [], loaded: false },
      },
      businessNote: null,
    };
  }),
  on(BookRideActions.resetFormFieldButtonClicked, state => {
    return {
      ...state,
      pickupLocation: null,
      dropoffLocation: null,
      quote: null,
      rideRoute: null,
    };
  }),
  on(BookRideActions.toggleRequestNowSwitched, (state, action) => {
    return {
      ...state,
      isPrebooking: action.isPrebooking,
      localPickupAtDateTime: null,
    };
  }),
  on(BookRideActions.pickupLocationChanged, (state, action) => {
    return {
      ...state,
      pickupLocation: action.pickupLocation,
      quote: null,
      rideRoute: null,
    };
  }),
  on(BookRideActions.dropoffLocationChanged, (state, action) => {
    return {
      ...state,
      dropoffLocation: action.dropoffLocation,
      isLoadingQuote: true,
      rideRoute: null,
    };
  }),
  on(BookRideActions.templateSelected, (state, action) => {
    return {
      ...state,
      templateId: action.templateId,
      quote: null,
      rideRoute: null,
    };
  }),
  on(BookRideActions.dropoffLocationSuggestionsLoaded, (state, action) => {
    return {
      ...state,
      isLoadingDropoffSuggestions: false,
      dropoffSuggestions: action.suggestions,
    };
  }),
  on(BookRideActions.pickupLocationSuggestionsLoaded, (state, action) => {
    return {
      ...state,
      isLoadingPickupSuggestions: false,
      pickupSuggestions: action.suggestions,
    };
  }),
  on(BookRideActions.areaIdChanged, (state, action) => {
    return {
      ...state,
      areaId: action.areaId,
      areaState: {
        ...state.areaState,
        areaId: action.areaId,
        packages: { items: [], loaded: false },
        features: { items: [], loaded: false },
        vehicleTypes: { items: [], loaded: false },
      },
    };
  }),
  on(BookRideActions.packagesLoaded, (state, action) => {
    return {
      ...state,
      areaState: {
        ...state.areaState,
        packages: { items: action.packages, loaded: true },
      },
    };
  }),
  on(BookRideActions.vehicleTypesLoaded, (state, action) => {
    return {
      ...state,
      areaState: {
        ...state.areaState,
        vehicleTypes: { items: action.vehicleTypes, loaded: true },
      },
    };
  }),
  on(BookRideActions.featuresLoaded, (state, action) => {
    return {
      ...state,
      areaState: {
        ...state.areaState,
        features: { items: action.features, loaded: true },
      },
    };
  }),
  on(BookRideActions.packageIdChanged, (state, action) => {
    return {
      ...state,
      packageId: action.packageId,
      vehicleTypeId: action.vehicleTypeId,
      featureIds: action.featureIds,
      quote: null,
      eta: null,
    };
  }),
  on(BookRideActions.quoteRequested, state => {
    return {
      ...state,
      isLoadingQuote: true,
      rideRoute: null,
    };
  }),
  on(BookRideActions.quoteChanged, (state, action) => {
    return {
      ...state,
      quote: action.quote,
      isLoadingQuote: false,
      rideRoute: action.quote?.polyline,
    };
  }),
  on(BookRideActions.upcomingRidesLoaded, (state, action) => {
    return {
      ...state,
      upcomingRides: action.rides,
    };
  }),
  on(BookRideActions.pickupLocationTyped, state => {
    return {
      ...state,
      isLoadingPickupSuggestions: true,
      quote: null,
      templateId: null,
    };
  }),
  on(BookRideActions.dropoffLocationTyped, (state, action) => {
    return {
      ...state,
      isLoadingDropoffSuggestions: action.query.length > 0,
      dropoffLocation: null,
      quote: null,
      rideRoute: null,
      dropoffSuggestions: [],
    };
  }),
  on(BookRideActions.rideRequested, state => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(BookRideActions.rideRequestFailed, state => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(BookRideActions.rideRequestSucceeded, state => {
    return {
      ...state,
      isLoading: false,
      quote: null,
      rideRoute: null,
      dropoffLocation: null,
    };
  }),
  on(BookRideActions.platformSettingsLoaded, (state, action) => {
    return {
      ...state,
      settings: action.settings,
    };
  }),
  on(BookRideActions.pickupAtDateTimeEdited, (state: State, action) => {
    return {
      ...state,
      quote: null,
      rideRoute: null,
    };
  }),
  on(BookRideActions.pickupAtDateTimeChanged, (state, action) => {
    return {
      ...state,
      localPickupAtDateTime: action.dateTime,
    };
  }),
  on(BookRideActions.paymentMethodTypeChanged, (state, action) => {
    return {
      ...state,
      paymentMethodType: action.paymentMethodType,
    };
  }),
  on(BookRideActions.areasLoaded, (state, action) => {
    return {
      ...state,
      areas: action.areas,
    };
  }),
  on(BookRideActions.rideSelected, (state, action) => ({
    ...state,
    selectedRideId: action.id,
    isLoadingRideDetails: true,
  })),
  on(BookRideActions.rideDeselected, (state, _) => ({
    ...state,
    selectedRideId: null,
    selectedRideBookingAggregate: null,
    isLoadingRideDetails: false,
  })),
  on(BookRideActions.rideDetailsChanged, (state, action) => ({
    ...state,
    isLoadingRideDetails: false,
    selectedRideBookingAggregate: action.aggregate,
    selectedRideAreaId: action.aggregate?.booking?.area_id,
  })),
  on(BookRideActions.sendTrackingLinkClicked, (state, _) => ({
    ...state,
    isTrackingLinkSending: true,
  })),
  on(BookRideActions.trackingLinkSent, (state, _) => ({
    ...state,
    isTrackingLinkSending: false,
  })),
  on(BookRideActions.sendTrackingLinkFailed, (state, _) => ({
    ...state,
    isTrackingLinkSending: false,
  })),
  on(BookRideActions.callDriverClicked, (state, _) => ({
    ...state,
    isCallingDriver: true,
  })),
  on(BookRideActions.driverCalled, (state, _) => ({
    ...state,
    isCallingDriver: false,
  })),
  on(BookRideActions.driverCallFailed, (state, _) => ({
    ...state,
    isCallingDriver: false,
  })),
  on(BookRideActions.bookingCancelClicked, (state, _) => ({
    ...state,
    isCancelBookingInProgress: true,
  })),
  on(BookRideActions.bookingCancelled, (state, _) => ({
    ...state,
    isCancelBookingInProgress: false,
  })),
  on(BookRideActions.bookingCancelFailed, (state, _) => ({
    ...state,
    isCancelBookingInProgress: false,
  })),
  on(BookRideActions.businessNoteLoaded, (state, action) => ({
    ...state,
    businessNote: action.note,
  })),
  on(BookRideActions.nearbyDriversChanged, (state, action) => ({
    ...state,
    eta: action.eta,
    nearbyDrivers: action.nearbyDrivers,
    nearbyDriversTelemetries: [],
  })),
  on(BookRideActions.allPackagesLoaded, (state, action) => ({
    ...state,
    allPackages: action.packages,
  })),
  on(BookRideActions.allVehicleTypesLoaded, (state, action) => ({
    ...state,
    allVehicleTypes: action.vehicleTypes,
  })),
  on(BookRideActions.nearbyDriversTelemetriesChanged, (state, action) => ({
    ...state,
    nearbyDriversTelemetries: action.nearbyDrivers,
  })),
);
