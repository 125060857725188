import { Component, Injectable, Type, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private router = inject(Router);

  private sidenavToggleSubject = new BehaviorSubject<boolean>(false);
  sidenavToggle$ = this.sidenavToggleSubject.asObservable();

  private componentSubject = new BehaviorSubject<{ component: Type<unknown>; inputs?: { [key: string]: unknown } }>(
    null,
  );
  component$ = this.componentSubject.asObservable();

  constructor() {
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.closeRightSidenav();
      });
  }

  loadComponent(component: Type<unknown>, inputs?: { [key: string]: unknown }) {
    this.componentSubject.next({ component, inputs });
  }

  openRightSidenav() {
    this.sidenavToggleSubject.next(true);
  }

  closeRightSidenav() {
    this.sidenavToggleSubject.next(false);
  }
}
