import { Component, OnInit, inject } from '@angular/core';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  private themeService = inject(ThemeService);

  isDarkTheme: boolean | null = null;

  ngOnInit() {
    this.watchTheme();
  }

  private watchTheme() {
    this.themeService.isDarkTheme$.pipe().subscribe(isDarkTheme => {
      this.isDarkTheme = isDarkTheme;
    });
  }
}
